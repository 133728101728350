:root {
  --bodyColor: "#000000";
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
ul li {
  padding: 0;
  margin: 0;
}

html {
  font-family: "Roboto Cn", sans-serif;
}

a {
  text-decoration: none !important;
  color: #eee;
}

/* =======Top Header======= */
.top_header {
  background-color: var(--bodyColor);
  padding: 15px 0;
  color: #062081;
}

.top_header .top_left_contact a {
  color: #fff;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 600;
}

.top_header .top_left_contact a i {
  color: #fff;
  margin-right: 5px;
}

.top_right_btn {
  text-align: right;
}

.top_right_btn a {
  color: #fff;
  /* margin-right: 15px; */
  font-size: 16px;
  font-weight: 600;
}

.top_right_btn a i {
  color: #fff;
  margin-right: 5px;
}

/* .top_right_btn a {
    padding: 10px 17px;
    background-color: #ffffff;
    color: #062081;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 800;
} */

.sticky-top {
  top: -1px !important;
}

.header {
  width: 100%;
  background-color: #f5f5f5;
}

.navbar-toggler .toggle_btn i {
  font-size: 30px;
  color: var(--bodyColor);
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--bodyColor) !important;
}

nav {
  text-transform: uppercase;
  font-size: 1.6rem;
}

.brand {
  text-transform: uppercase;
  font-size: 20px !important;
  font-weight: 700;
  font-family: serif;
  transform: translateX(-100rem);
  animation: slideIn 0.5s forwards;
  color: #000 !important;
}

.brand span {
  color: var(--bodyColor);
  padding-right: 7px;
}

/* .navbar-collapse {
    flex-basis: 100% !important;
    flex-grow: 1 !important;
    align-items: center;
} */
.header_menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
  transform: translateX(100rem);
  animation: slideIn 0.5s forwards;
}

nav ul li:nth-child(1) {
  animation-delay: 0s;
}

nav ul li:nth-child(2) {
  animation-delay: 0.5s;
}

nav ul li:nth-child(3) {
  animation-delay: 1s;
}

nav ul li:nth-child(4) {
  animation-delay: 1.5s;
}

nav ul li:nth-child(5) {
  animation-delay: 2s;
}

nav ul li a {
  margin: 0 2rem;
  position: relative;
  letter-spacing: 2px;
  color: #000 !important;
  font-size: 17px !important;
  font-weight: 700 !important;
  padding-right: 0 !important;
}

ul.BlogDropbtnMenu {
  padding: 10px;
  background-color: whitesmoke;
}
.BlogDropbtn {
  margin-right: 0;
}
.BlogDropbtnlink {
  margin: 0;
  padding: 6px !important;
}

p.brand_text_servier {
  color: var(--bodyColor);
  font-size: 10px;
  letter-spacing: 18px;
  margin-bottom: 0;
  margin-top: -7px;
}

p.brand_text_area {
  margin-bottom: 0;
  margin-top: -8px;
  letter-spacing: 3.6px;
}

nav ul li a:last-child {
  margin-right: 0;
}

nav ul li a::before,
nav ul li a::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: var(--bodyColor);
  left: 0;
  transform: scaleX(0);
  transition: all 0.5s;
}

nav ul li a::before {
  top: 0;
  transform-origin: left;
}

nav ul li a::after {
  bottom: 0;
  transform-origin: right;
}

nav ul li a:hover::before,
nav ul li a:hover::after {
  transform: scaleX(1);
}

@keyframes slideIn {
  from {
  }

  to {
    transform: translateX(0);
  }
}

.nav-link.active {
  border-bottom: 2px solid var(--bodyColor) !important;
}

/* Home slider */

/* Page Banner */

.PageBanner_section {
  width: 100%;
  /* height: auto; */
  /* background: url('../public/image/blogDetialsBG2.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
}

.PageBanner_section .bannerimg,
.PageBanner_section .blog_iframe {
  width: 100%;
  height: 500px;
  object-fit: fill;
}

.banner_desc {
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  background: url("../public/image/blogDetialsBG2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner_desc .BannerDsceBox h2 {
  font-size: 38px;
  color: #fff;
}

.banner_section {
  width: 100%;
  height: 300px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

nav.banner_box {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

h2.breaddcrumb_title {
  margin-bottom: 0;
  color: #fff;
  font-size: 40px;
}

.banner_box .banner_home_text a {
  color: #fff;
  font-size: 16px;
}

.banner_box .banner_current_text {
  color: #fff !important;
  padding-left: 0 !important;
  margin-left: 10px;
  font-size: 16px;
  margin-top: 11px;
}

.breadcrumb-item + .breadcrumb-item::before {
  color: var(--bodyColor) !important;
  content: "<<" !important;
}

/* About Section */
.devider_border_color {
  border-top: 1px solid #fff;
  opacity: 0.8;
}

.section_padding {
  padding-top: 60px;
}

.section_padding_bottom {
  padding-bottom: 60px;
}

.section_margin_top {
  margin-top: 60px;
}

.about_img_box .about_img {
  width: 100%;
  height: 100%;
}

.about_text_box h1.about_title {
  font-size: 38px;
  color: var(--bodyColor);
  font-family: serif;
  font-weight: 900;
  margin-bottom: 20px;
}

.about_text_box p.about_desc {
  font-size: 16px;
  text-align: justify;
  line-height: 1.6;
  margin-bottom: 30px;
}

.about_text_box a.about_btn {
  background-color: var(--bodyColor);
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 8px;
  color: #fff;
}

/* videoForm */
.section_bg_color {
  background: #f0f2f5;
}

.section_title {
  text-align: center;
  margin-bottom: 45px;
}

.section_title .title {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 30px;
}

.post-thumbnail {
  width: 100%;
  height: 480px;
}

.videoForm_section iframe {
  border: 0;
  /* border-radius: 12px; */
}

#form_id iframe {
  border: 3px solid var(--bodyColor);
  border-radius: 12px;
}

/* Blog Section */
.blog_section .blog_box {
  border: 1px solid #8080807a;
  border-radius: 10px;
}

.blog_box .blog_img_box {
  height: 200px;
}

.blog_box img.blog_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
}

.blog_box .Blog_desc_box {
  padding: 15px;
  height: 160px;
}

.blog_box p.blot_date {
  color: #062081;
  margin-bottom: 10px;
  font-size: 13px;
  font-weight: 500;
}

.blog_box p.blot_date i {
  margin-right: 7px;
}

.blog_box h5.blog_title {
  font-size: 20px;
  color: var(--bodyColor);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.Blog_desc_box .blog_desc {
  margin-bottom: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* Footer Section */
footer.footer_section {
  /* padding: 20px 0px; */
  background: whitesmoke;
}

.footer-top {
  padding: 40px 0 30px;
}

.footer .single-widget {
  margin: 15px 0;
}

.footer .single-widget ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer .list li {
  line-height: 30px;
  color: #444464;
}

.footer .list li i {
  margin-right: 5px;
  color: #514a4b;
}

.footer .list li a {
  color: #444464;
}

.footer .social {
  text-align: left;
  margin: 25px 0 0 0 !important;
}

.footer .social li {
  display: inline-block;
  margin-right: 5px;
}

.footer .social li a {
  display: block;
  height: 40px;
  width: 40px;
  line-height: 40px;
  background: var(--bodyColor);
  text-align: center;
  margin: 0;
  padding: 0;
  color: #fff;
  border-radius: 50%;
}

.footer .social li i {
  color: #fff !important;
  margin-right: 0 !important;
}

.footer .single-widget h2 {
  font-size: 22px;
  margin-bottom: 15px;
  color: #514a4b;
  position: relative;
  font-weight: 500;
}

.footer .useful-links ul li {
  position: relative;
  line-height: 30px;
  width: 49%;
  float: left;
  margin-right: 1%;
}

.footer .useful-links ul li i {
  margin-right: 10px;
}

.footer .useful-links ul li a {
  color: #444464;
  font-size: 14px;
}

.footer .list li {
  line-height: 30px;
  color: #444464;
}

.footer .list li i {
  margin-right: 5px;
  color: #514a4b;
}

.footer .list li a {
  color: #444464;
}

.footer .footer_bottom {
  border-top: #444464 1px solid;
  padding: 20px 0px;
}

.left_footer p {
  margin-bottom: 0;
  color: #444464;
  font-size: 16px;
  font-weight: 500;
}

.left_footer p a {
  color: var(--bodyColor);
  font-size: 16px;
  font-weight: 700;
  margin: 0 3px;
}

.footer_contant {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.right_footer .footer_menu {
  display: flex;
  text-align: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.right_footer .footer_link {
  padding: 0px 10px;
}

.right_footer .footer_link a {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  transition: all 0.3s linear;
}

.right_footer .footer_link a:hover {
  color: var(--bodyColor);
}

.foot_right {
  display: flex;
  align-items: center;
  justify-content: right;
}

/* Services Section */
.service_bg {
  background-image: url("../public/image/bg5.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.sevice_box {
  box-shadow: 0 6px rgb(70 70 70 / 25%);
  border-radius: 5px;
  padding: 25px 21px 5px;
  text-align: center;
  height: 160px;
  line-height: 65px;
  background-color: #fff;
  transition: all 0.3s linear;
}

.sevice_box i {
  font-size: 30px;
  color: var(--bodyColor);
}

.sevice_box:hover.sevice_box i {
  color: #fff;
}

.sevice_box:hover {
  background-color: var(--bodyColor);
}

.sevice_box svg {
  margin-top: 27px;
  margin-bottom: -20px;
  transition: all 0.3s linear;
}

.sevice_box svg path {
  transition: all 0.3s linear;
  fill: var(--bodyColor);
}

.sevice_box h4 {
  font-size: 16px;
  font-weight: 700;
  color: #0d0d0d;
  /* padding-top: 28px; */
  transition: all 0.3s linear;
}

.sevice_box:hover.sevice_box h4 {
  color: #fff;
}

.sevice_box:hover svg path {
  fill: #fff;
}

/* How Work Section */
/* .steps {
    background: url('../public/image/line.jpg') 0 0/.4% 89% no-repeat;
    margin-left: 30px;
} */
/* .steps ul {
    margin-left: -30px;
} */
.steps ul li {
  padding: 0 0 55px;
}

.step_one {
  vertical-align: middle;
  padding-left: 10px;
}

.d-inline-block.step_one span {
  color: #fa3737;
  font-size: 16px;
}

.d-inline-block.step_one h5,
p.name {
  font-weight: 700;
  font-size: 20px;
}

.askStapBox3 {
  background-color: var(--bodyColor);
  border-radius: 8px;
  margin-left: 5px;
  width: 50px;
  height: 50px;
  padding-top: 10px;
  text-align: center;
}

.askStapBox3 .askStap3 i {
  font-size: 30px;
  color: #fff;
}

/* Why Chhose Us section */
.whyChoose_section .head_img {
  text-align: center;
  height: 200px;
}

.whyChoose_section .head_img img {
  width: 100%;
  height: 100%;
  object-fit: none;
}

.whyChoose_section .choose_one {
  background-color: #fff;
  border-radius: 5px;
  margin-top: -69px;
}

.whyChoose_section .choose_one ul {
  padding: 80px 30px 10px;
}

.whyChoose_section .choose_one ul li {
  padding: 10px 0;
}

.whyChoose_section .choose_one ul li span {
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  background-color: var(--bodyColor);
}

.whyChoose_section .choose_one ul li h4,
.textbolder {
  font-weight: 600;
}

.whyChoose_section .choose_one ul li p {
  margin-top: -13px !important;
  color: #464646;
  font-weight: 500;
  font-size: 18px;
}

/* Client Section  */

.client_section .slider-card {
  background: #fff;
  box-shadow: 0 0px 15px rgba(70, 70, 70, 0.25);
}

.client_section .slider {
  margin-bottom: 30px;
}

.owl-item.active.center .slider-card {
  transform: scale(1.1);
  opacity: 1;
  background: #fff;
  color: #000;
}

.slider-card {
  padding: 0;
  margin: 90px 15px 40px;
  border-radius: 10px;
  transform: scale(0.9);
  opacity: 0.5;
  transition: 0.3s;
  box-sizing: border-box;
}

.slider-card img {
  border-radius: 5px 5px 0 0;
  margin-top: -60px;
}

.client_section .slider-card .imgs {
  width: 100px;
  border-radius: 50%;
  height: 100px;
}

.client_section .slider-card .nameedu {
  padding: 8px 20px;
}

.client_section .slider-card .nameedu h3 {
  font-weight: 700;
  color: var(--bodyColor);
}

.client_section .slider-card .nameedu p {
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  padding-top: 8px;
  padding-bottom: 20px;
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  color: #8a8a8a;
  margin-bottom: 0;
}

.client_section .slider-card .clientwords {
  font-weight: 500;
  font-size: 15px;
  color: #0d0d0d;
  padding: 24px 40px 40px;
}

/* Client Section ==== 1 BlogDetails*/

.client_section1 .slider-card1 {
  background: #fff;
  box-shadow: 0 0px 15px rgba(70, 70, 70, 0.25);
}

.client_section1 .slider1 {
  margin-bottom: 30px;
}

/* .owl-item.active.center .slider-card1 {
    transform: scale(1.1);
    opacity: 1;
    background: #fff;
    color: #000
} */

.slider-card1 {
  padding: 0;
  margin: 90px 15px 40px;
  border-radius: 10px;
  transform: scale(0.9);
  opacity: 1;
  transition: 0.3s;
  box-sizing: border-box;
}

.slider-card1 img {
  border-radius: 5px 5px 0 0;
  margin-top: -60px;
}

.client_section1 .slider-card1 .imgs {
  width: 100px;
  border-radius: 50%;
  height: 100px;
}

.client_section1 .slider-card1 .nameedu1 {
  padding: 8px 20px;
}

.client_section1 .slider-card1 .nameedu1 h3 {
  font-weight: 700;
  color: var(--bodyColor);
}

.client_section1 .slider-card1 .nameedu1 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 10px;
  padding-top: 8px;
  padding-bottom: 20px;
  border: 0;
  border-bottom: 1px solid #e0e0e0;
  color: #8a8a8a;
  margin-bottom: 0;
}

.client_section1 .slider-card1 .clientwords1 {
  font-weight: 500;
  font-size: 15px;
  color: #0d0d0d;
  padding: 24px 40px 40px;
}

/* Home Contact  */
.homeContact_img {
  height: 450px;
}

/* Blog Details */
.single-post .post-header {
  margin-bottom: 40px;
}

.post-categories {
  margin-bottom: 9px;
  font-size: 0.8rem;
  line-height: 120%;
  font-weight: 900;
  color: #0093c2;
}

.single-post .post-categories {
  margin-bottom: 12px;
  font-size: 12px;
}

.post-categories {
  color: var(--bodyColor);
  letter-spacing: 1px;
  text-transform: uppercase;
}

a {
  text-decoration: none;
}

.post-categories a {
  transition: 0.1s ease-in-out;
  color: var(--bodyColor);
  font-size: 13px;
}

.single-post .post-title {
  font-size: 35px;
  line-height: 135%;
  font-weight: 900;
  letter-spacing: -0.01em;
}

.single-post .post-comments {
  /* float: right; */
  margin-right: 10px;
  color: var(--bodyColor);
  display: flex;
  align-items: center;
  font-size: 14px;
}

.Post_comm_shere {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.share-post a.fb_twitter {
  background: #44b1e4;
}

.share-post a.fb_linkedin {
  background: #fe4c1c;
}

.share-post a.fb_whatsapp {
  background: #40c515;
}

.share-post a.fb_share {
  background: #4661c5;
}

.share-post a {
  float: left;
  overflow: hidden;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  font-weight: 500;
  border-radius: 19px;
  color: #fff !important;
  margin-left: 15px;
  transition: all 0.4s ease-in-out;
}
.blogdetailsDate {
  float: right;
  padding-right: 0 !important;
}

.share-post a:hover {
  transform: translateY(-5px);
}

.single-post .post-comments .fa {
  position: relative;
  top: -1px;
  margin-right: 6px;
}

.single-post .post-meta {
  margin-top: 10px;
  border-top: 1px solid #ddd;
  /* padding-top: 16px; */
  font-size: 1rem;
  color: #999;
  font-weight: 700;
  line-height: 120%;
  margin: 6px 0 0;
}

.single .post-meta .post-meta-date {
  color: var(--bodyColor);
  padding-right: 20px;
  font-size: 14px;
}

.single .post-meta .post-meta-date .bogdetailsicon {
  padding-right: 5px;
}

.share-post {
  display: flex;
  align-items: center;
}
.single-post .post-image {
  display: block;
  clear: both;
  margin-bottom: 40px;
  position: relative;
}

.post-image img {
  display: block;
}

.single-post .post-inner {
  position: relative;
  z-index: 5;
}

.post-content p {
  line-height: 1.7;
  text-align: justify;
}

.post-tags {
  margin: 30px 0;
}

.post-tags a {
  background-color: #ddd;
  color: #444;
  display: inline-block;
  font-size: 14px !important;
  font-weight: 900;
  line-height: 1;
  margin: 0 8px 8px 11px;
  padding: 5px 6px 6px 5px;
  position: relative;
  transition: 0.1s ease-in-out;
}

.post-tags a:before {
  content: "";
  display: block;
  border: 11px solid transparent;
  border-left-width: 0;
  border-right-color: #ddd;
  position: absolute;
  top: 0;
  left: -11px;
  transition: 0.1s ease-in-out;
}

.post-tags a:hover {
  background-color: var(--bodyColor);
  color: #fff;
}

.site_bar_form h4.form_heading {
  text-align: center;
  padding: 10px 0;
  font-size: 21px;
  font-weight: 700;
  color: var(--bodyColor);
  background-color: whitesmoke;
}

#siteForm iframe .ifram_form_id html body .ticket_form {
  background-color: transparent !important;
}

body.ticket_form {
  background-color: transparent;
}

.sitebar_service {
  background-color: #f2f2f2;
}

.sitebar_service h4 {
  margin-top: 10px;
  text-align: center;
  padding: 10px 0;
  font-size: 21px;
  font-weight: 700;
  color: var(--bodyColor);
  text-transform: uppercase;
}

.sitebar_service ul li {
  list-style: none;
}

.sitebar_service ul li a {
  background: #f2f2f2;
  color: #1f1f1f;
  font-size: 14px;
  display: block;
  line-height: 40px;
  width: 100%;
  padding-left: 18px;
  border-bottom: 1px solid #cecece;
  text-transform: uppercase;
}

.sitebar_service ul li a:hover {
  background: #173e62;
  color: #fff;
  text-decoration: none;
}

div#siteForm {
  border: 2px solid var(--bodyColor);
}

.BlogTopForm {
  display: none;
}

/* Contact section CSS */
.contact_box {
  border-radius: 10px;
  padding: 20px 20px 1px;
  height: 170px;
  text-align: center;
}

.contact_box h5 {
  font-weight: 700;
  font-size: 20px;

  color: #464646;
  padding: 5px 0;
}

.contact_box ul li span {
  font-weight: 400;
  font-size: 16px;
  color: #464646;
}

.contact_box ul li span a {
  font-weight: 400;
  font-size: 16px;
  color: var(--bodyColor);
}

.contact_box ul li i {
  transition: all 0.3s linear;
  color: var(--bodyColor);
  color: var(--bodyColor);
  font-size: 35px;
}

.contact_address .brach_bg_one {
  color: #fff;
  padding: 28px;
  height: 220px;
  text-align: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 15px;
  display: flex;
  align-items: center;
}

.contact_address .AddBox_bg1 {
  background: url(../public/image/contact/brach1.png);
}

.contact_address .AddBox_bg2 {
  background: url(../public/image/contact/pune.jpeg);
}

.contact_address .AddBox_bg3 {
  background: url(../public/image/contact/brach3.png);
}

.brach_bg_one ul li i {
  font-size: 35px;
}

.brach_bg_one h5 {
  line-height: 45px;
  color: #fff;
  font-family: Montserrat;
  font-style: normal;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

/* Content Section CSS */
.content_heading {
  margin-bottom: 25px;
  text-align: center;
}

.content_heading .content_title {
  color: var(--bodyColor);
  text-align: center;
  padding: 8px;
  font-size: 30px;
  border-bottom: 2px solid;
  display: inline-block;
}

.contect_desc .content_pere {
  line-height: 23px;
  font-size: 16px;
}

.contect_desc p {
  color: #444;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 500;
}

.contect_desc ul li strong {
  color: var(--bodyColor);
}

.contect_desc ul li::marker {
  color: var(--bodyColor);
}

/* Contact Botton */
.wa-float-img-circle {
  width: 56px;
  height: 56px;
  bottom: 20px;
  right: 20px;
  top: 50%;
  border-radius: 100%;
  position: fixed;
  z-index: 99999;
  display: flex;
  transition: all 0.3s;
  align-items: center;
  justify-content: center;
  background: #25d366;
}

.call-float-img-circle {
  width: 56px;
  height: 56px;
  bottom: 100px;
  right: 20px;
  top: 40%;
  border-radius: 100%;
  position: fixed;
  z-index: 99999;
  display: flex;
  transition: all 0.3s;
  align-items: center;
  justify-content: center;
  background: var(--bodyColor);
}

.wa-float-img-circle:before {
  position: absolute;
  content: "";
  background-color: #25d366;
  width: 70px;
  height: 70px;
  bottom: -7px;
  right: -7px;
  border-radius: 100%;
  animation: wa-float-circle-fill-anim 2.3s infinite ease-in-out;
  transform-origin: center;
  opacity: 0.1;
}

.call-float-img-circle:before {
  position: absolute;
  content: "";
  background-color: var(--bodyColor);
  width: 70px;
  height: 70px;
  bottom: -7px;
  right: -7px;
  border-radius: 100%;
  animation: call-float-circle-fill-anim 2.3s infinite ease-in-out;
  transform-origin: center;
  opacity: 0.1;
}

.wa-float-img-circle i,
.call-float-img-circle i {
  color: #fff;
  font-size: 33px;
}

@keyframes wa-float-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
  }
}

@keyframes call-float-circle-fill-anim {
  0% {
    transform: rotate(0) scale(0.7) skew(1deg);
  }

  50% {
    transform: rotate(0) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0) scale(0.7) skew(1deg);
  }
}

/* Media Queries */
@media (max-width: 768px) {
  hr.before_border,
  hr.after_border {
    display: none;
  }

  .left_footer p {
    font-size: 13px;
    font-weight: 500;
  }

  .left_footer p a {
    font-size: 13px;
  }

  .top_right_btn {
    text-align: start;
  }

  .last_staps6 {
    padding: 0 !important;
  }

  .Post_comm_shere {
    margin-top: 15px;
  }

  .brand {
    font-size: 16px !important;
  }

  p.brand_text_servier {
    font-size: 8px;
    margin-top: -6px;
    letter-spacing: 15px;
  }

  p.brand_text_area {
    margin-top: -6px;
    letter-spacing: 3.2px   ;
  }

  .footer-top {
    padding: 10px 0 30px;
  }

  .post-thumbnail {
    height: 250px;
    margin-bottom: 20px;
  }

  .section_padding {
    padding-top: 30px;
  }

  .section_padding_bottom {
    padding-bottom: 30px;
  }

  .section_margin_top {
    margin-top: 30px;
  }

  .about_text_box h1.about_title {
    font-size: 28px;
    margin-top: 20px;
  }

  .top_header {
    padding: 5px 0;
  }

  .section_title {
    margin-bottom: 20px;
  }

  .call-float-img-circle,
  .wa-float-img-circle {
    width: 45px;
    height: 45px;
    right: 15px;
  }

  .call-float-img-circle:before,
  .wa-float-img-circle:before {
    width: 60px;
    height: 60px;
  }

  .call-float-img-circle {
    top: 42%;
  }

  .wa-float-img-circle i,
  .call-float-img-circle i {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .foot_right {
    justify-content: start;
  }

  .header_menu {
    justify-content: flex-start;
  }

  .content_heading .content_title {
    width: 100%;
  }

  .PageBanner_section .bannerimg,
  .PageBanner_section .blog_iframe,
  .banner_desc {
    height: 250px;
    object-fit: fill;
  }

  .banner_desc .BannerDsceBox h2 {
    font-size: 28px;
  }

  .homeContact_img {
    height: 300px;
  }

  .steps ul li {
    padding: 0 0 40px;
  }

  .top_header {
    display: none;
  }
  .BlogSiteForm {
    display: none;
  }
  .BlogTopForm {
    display: block;
    margin-bottom: 40px;
  }
}
